<script>
    import { mapGetters } from "vuex";
    import ProductCard from "@tb/components/ProductCard";

    export default {
        name: "SectionProducts",
        components: {
            ProductCard,
        },
        props: ["id", "section"],
        computed: {
            ...mapGetters("Catalog", ["mainCategories"]),
            calcCategory() {
                return this.mainCategories.filter((el) => el.id == this.id)[0];
            },
        },
    };
</script>

<template>
    <div class="b-section-goods" v-if="section.length > 0">
        <div class="b-section-goods__header">
            <div class="b-section-goods__title">
                {{ calcCategory.name }}
                <div class="b-section-goods__number"></div>
            </div>
        </div>
        <div class="b-section-goods__body">
            <product-card
                v-for="goods in section"
                :goods="goods"
                :key="goods.id"
            ></product-card>

            <router-link
                :to="{
                    name: 'CatalogCategory',
                    params: { slug: calcCategory.slug },
                }"
                class="b-section-goods__link"
            >
                <div class="b-section-goods__link-text">
                    Все товары <br />
                    категории
                </div>
                <svg width="53" height="51" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M52.5 46.741V.708A.693.693 0 0051.79 0c-.102 0-.203.05-.305.05C27.743 7.994 8.871 26.508.551 50.03a.455.455 0 00-.051.252c0 .405.304.709.71.709h46.978c0 .05 4.312.05 4.312-4.25z"
                        fill="#17479E"
                    />
                </svg>
            </router-link>
        </div>
    </div>
</template>

<style lang="scss">
    .b-section-goods {
        margin-bottom: 32px;

        @include bp($bp-desktop-sm) {
            margin-bottom: 48px;
        }
    }

    .b-section-goods__header {
        @include bp($bp-tablet-sm) {
            margin-bottom: 24px;
        }

        @include bp($bp-desktop-sm) {
            margin-bottom: 40px;
        }
    }

    .b-section-goods__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;

        @include bp($bp-desktop-sm) {
            font-size: 24px;
        }
    }

    .b-section-goods__number {
        display: inline;
        font-size: 13px;
        line-height: 19px;
        color: var(--secondary-text);

        @include bp($bp-desktop-sm) {
            margin-left: 8px;
        }
    }

    .b-section-goods__body {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -12px;

        @include bp($bp-tablet-sm) {
            flex-wrap: nowrap;
            margin: 0;
        }

        .goods-card {
            width: calc(50% - 24px);
            margin: 24px 12px 0;

            @include bp($bp-tablet-sm) {
                width: calc(((100% - 182px) - 24px) / 3);
                margin: 0 24px 0 0;
            }

            @include bp($bp-desktop-sm-for-card) {
                width: calc(((100% - 182px) - 4px) / 3);
                margin: 0;
            }
        }
    }

    .b-section-goods__link {
        position: relative;
        width: calc(50% - 24px);
        margin: 24px 12px 0;
        background-color: var(--neutral--light);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;

        @include bp($bp-tablet-sm) {
            margin: 0;
            width: 182px;
            flex-shrink: 0;
        }

        @include bp($bp-desktop-sm-for-card) {
            margin-left: 12px;
        }

        &:after {
            content: "";
            @include center-absolute(0);
            border: 1px solid var(--primary-border--light);
            opacity: 0;
            border-radius: 4px;
            transition: 0.2s ease-in-out all;
        }

        svg {
            position: absolute;
            bottom: 0;
            right: 0;
        }

        &:hover {
            &:after {
                opacity: 1;
            }
        }
    }

    /*<!--.b-section-goods__link-img {-->*/
    /*<!--    position: relative;-->*/
    /*<!--    padding-top: 112%;-->*/
    /*<!--    margin-bottom: 8px;-->*/

    /*<!--    @include bp($bp-desktop-sm) {-->*/
    /*<!--        margin-bottom: 16px;-->*/
    /*<!--    }-->*/

    /*<!--    img {-->*/
    /*<!--        @include center-absolute(0);-->*/
    /*<!--        margin: auto;-->*/
    /*<!--        max-width: 100%;-->*/
    /*<!--        max-height: 100%;-->*/
    /*<!--        border-radius: 3px;-->*/
    /*<!--    }-->*/
    /*<!--}-->*/

    .b-section-goods__link-text {
        /*display: flex;*/
        /*justify-content: space-between;*/
        /*align-items: center;*/
        font-size: 13px;
        line-height: 16px;
        font-weight: 600;
        color: var(--primary);
        text-align: center;

        @include bp($bp-desktop-sm) {
            font-size: 14px;
            line-height: 20px;
        }

        /*<!--svg {-->*/
        /*<!--    fill: var(--primary);-->*/
        /*<!--}-->*/
    }
</style>
