<script>
    import { ContentLoader } from "vue-content-loader";
    export default {
        name: "CatalogSkeleton",
        components: {
            ContentLoader,
        },
    };
</script>

<template>
    <div class="skltn">
        <div class="skltn__wrap">
            <content-loader
                class="skltn--dsktp"
                :width="100"
                :height="43"
                :speed="1.5"
                primaryColor="#EBEFF2"
                :secondaryOpacity="0.5"
            >
                <rect x="0" y="0" rx="1" ry="1" width="45" height="3" />
                <rect x="0" y="7" rx="1" ry="1" width="100" height="36" />
            </content-loader>
            <content-loader
                class="skltn--dsktp"
                :width="100"
                :height="43"
                :speed="1.5"
                primaryColor="#EBEFF2"
                :secondaryOpacity="0.5"
            >
                <rect x="0" y="0" rx="1" ry="1" width="45" height="3" />
                <rect x="0" y="7" rx="1" ry="1" width="100" height="36" />
            </content-loader>
            <content-loader
                class="skltn--dsktp"
                :width="100"
                :height="43"
                :speed="1.5"
                primaryColor="#EBEFF2"
                :secondaryOpacity="0.5"
            >
                <rect x="0" y="0" rx="1" ry="1" width="45" height="3" />
                <rect x="0" y="7" rx="1" ry="1" width="100" height="36" />
            </content-loader>
        </div>

        <div class="skltn__wrap">
            <content-loader
                class="skltn--mbl"
                :width="100"
                :height="172"
                :speed="1.5"
                primaryColor="#EBEFF2"
                :secondaryOpacity="0.5"
            >
                <rect x="0" y="0" rx="1" ry="1" width="80" height="8" />
                <rect x="0" y="15" rx="1" ry="1" width="100" height="75" />
                <rect x="0" y="97" rx="1" ry="1" width="100" height="75" />
            </content-loader>
            <content-loader
                class="skltn--mbl"
                :width="100"
                :height="172"
                :speed="1.5"
                primaryColor="#EBEFF2"
                :secondaryOpacity="0.5"
            >
                <rect x="0" y="0" rx="1" ry="1" width="80" height="8" />
                <rect x="0" y="15" rx="1" ry="1" width="100" height="75" />
                <rect x="0" y="97" rx="1" ry="1" width="100" height="75" />
            </content-loader>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .skltn--dsktp {
        display: none;
        @include bp($bp-tablet-sm) {
            display: block;
        }

        &:not(:first-child) {
            margin-top: 30px;

            @include bp($bp-desktop-sm) {
                margin-top: 46px;
            }
        }
    }

    .skltn--mbl {
        @include bp($bp-tablet-sm) {
            display: none;
        }

        &:not(:first-child) {
            margin-top: 24px;
        }
    }
</style>
