<script>
    export default {
        name: "CatalogBanner",
        props: {
            title: String,
            description: String,
            src: String,
            isCatalog: Boolean,
        },
    };
</script>

<template>
    <div class="b-banner-catalog">
        <div class="b-banner-catalog__block">
            <div class="b-banner-catalog__title">{{ title }}</div>
            <div class="b-banner-catalog__text" v-html="description"></div>
        </div>
        <img v-if="isCatalog" src="@tb/assets/images/catalog-main.jpg" :alt="title" />
        <img v-else :src="src" alt="" />
    </div>
</template>

<style lang="scss">
    .b-banner-catalog {
        display: none;

        @include bp($bp-desktop-sm) {
            display: block;

            position: relative;
            margin-bottom: 24px;
            height: 200px;

            img {
                position: absolute;
                top: 0;
                left: 0;
                @include box(100%);
                object-fit: cover;
            }
        }
    }

    .b-banner-catalog__block {
        position: absolute;
        top: 50%;
        left: 32px;
        transform: translateY(-50%);
        z-index: 1;
    }

    .b-banner-catalog__title {
        font-weight: 600;
        font-size: 40px;
        line-height: 44px;
        margin-bottom: 4px;
    }

    .b-banner-catalog__text {
        max-width: 352px;
        font-size: 16px;
        line-height: 24px;
    }
</style>
