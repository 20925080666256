<script>
    import TheSvgIcon from "@main/packages/TheSvgIcon";

    export default {
        name: "CatalogMenu",
        components: {
            TheSvgIcon,
        },
        props: {
            catalogList: Array,
        },
        computed: {
            firstEl() {
                return this.catalogList[0];
            },
            listCategory() {
                return this.catalogList.slice(1);
            },
        },
        methods: {
            closeMobileMenu(type) {
                if (type === "section" && this.$route.params.slug !== this.firstEl.slug) {
                    this.$router.push({
                        name: "CatalogCategory",
                        params: { slug: this.firstEl.slug },
                    });
                }

                this.$emit("close-mobile-menu");
            },
        },
    };
</script>

<template>
    <div class="catalog-menu">
        <div class="catalog-menu__wrap">
            <router-link
                :to="{
                    name: 'CatalogCategory',
                    params: { slug: firstEl.slug },
                }"
                class="catalog-menu__title"
                exact
            >
                {{ firstEl.name }}
                <the-svg-icon name="arrow_right" is-common :size-w="24"></the-svg-icon>
            </router-link>

            <div class="catalog-menu__title-mobile-clone" @click="closeMobileMenu('section')">
                Перейти
            </div>
        </div>

        <ul class="catalog-menu__list">
            <li class="catalog-menu__item" v-for="(category, i) in listCategory" :key="i">
                <router-link
                    @click.native.prevent="closeMobileMenu"
                    :to="{
                        name: 'CatalogCategory',
                        params: { slug: category.slug },
                    }"
                    class="catalog-menu__link"
                    exact
                >
                    {{ category.name }}
                    <the-svg-icon name="arrow_right" is-common :size-w="24"></the-svg-icon>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<style lang="scss">
    .catalog-menu {
        border-bottom: 1px solid var(--primary-border--light);

        &.is-active {
            .catalog-menu__list {
                display: block;
            }

            .catalog-menu__title {
                svg {
                    display: none;
                }
            }

            .catalog-menu__title-mobile-clone {
                display: block;

                @include bp($bp-desktop-sm) {
                    display: none;
                }
            }
        }

        @include bp($bp-desktop-sm) {
            margin-bottom: 24px;
            border-bottom: none;
        }
    }

    .catalog-menu__wrap {
        position: relative;
    }

    .catalog-menu__title {
        display: flex;
        justify-content: space-between;
        padding: 12px 16px;
        font-size: 15px;
        line-height: 24px;
        color: var(--primary-text);

        &.active {
            color: var(--primary);
        }

        svg {
            transform: rotate(90deg);
        }

        @include bp($bp-desktop-sm) {
            font-weight: 600;
            line-height: 20px;
            padding: 0;
            outline-offset: 3px;

            svg {
                display: none;
            }
        }
    }

    .catalog-menu__title-mobile-clone {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        font-size: 12px;
        line-height: 24px;
        padding: 12px 16px;
        color: #17479e;
        text-align: right;
        z-index: 1;
    }

    .catalog-menu__list {
        display: none;
    }

    .catalog-menu__item {
        @include bp($bp-desktop-sm) {
            margin-top: 8px;
        }
    }

    .catalog-menu__link {
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        line-height: 24px;
        color: var(--secondary-text);
        background-color: var(--neutral--light);
        padding: 16px 12px;

        @include bp($bp-desktop-sm) {
            font-size: 13px;
            line-height: 17px;
            background-color: transparent;
            padding: 0;
            outline-offset: 3px;

            svg {
                display: none;
            }
        }
        &.active {
            color: var(--primary);
        }
    }
</style>
