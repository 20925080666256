<script>
    import { mapGetters } from "vuex";
    import TheSvgIcon from "@main/packages/TheSvgIcon";
    import CatalogMenu from "@tb/components/CatalogMenu";
    import { scrollState, isMobile, stickyScroll } from "@/utils";

    export default {
        name: "CatalogContainer",
        components: {
            TheSvgIcon,
            CatalogMenu,
        },
        props: {
            isCatalog: Boolean,
        },
        data() {
            return {
                isAside: false,
            };
        },
        computed: {
            ...mapGetters("Catalog", ["formattedCategoryNames"]),
        },
        mounted() {
            if (!isMobile()) {
                window.addEventListener("scroll", this.stickyCatalogScroll, false);
            }
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.stickyCatalogScroll);
        },
        methods: {
            showCategory() {
                this.isAside = true;
                scrollState.disable();
            },
            closeCategory() {
                this.isAside = false;
                scrollState.enable();
            },
            stickyCatalogScroll() {
                stickyScroll({
                    el: document.querySelector(".js-scroll-catalog"),
                    elWrap: document.querySelector(".js-scroll-catalog-wrap"),
                    topMargin: 124,
                });
            },
        },
    };
</script>

<template>
    <div class="catalog-container">
        <h1 class="catalog-container__mobile-title">
            <slot name="title"></slot>
        </h1>
        <div class="catalog-container__mobile-desc">
            <slot name="description"></slot>
        </div>

        <div class="catalog-container__mobile-category" @click="showCategory">
            <the-svg-icon
                class="catalog-container__mobile-icon"
                name="menu_list"
                :size-w="24"
            ></the-svg-icon>
            Категории
            <the-svg-icon
                class="catalog-container__mobile-arrow"
                name="arrow_right"
                is-common
                :size-w="16"
                :size-h="24"
            ></the-svg-icon>
        </div>

        <aside
            class="catalog-container__aside js-scroll-catalog"
            :class="{
                'catalog-container__aside--active': isAside,
            }"
        >
            <div class="catalog-container__aside-wrap js-scroll-catalog-wrap">
                <div class="catalog-container__aside-header">
                    <div class="catalog-container__aside-title">
                        <slot name="title"></slot>
                    </div>
                    <div class="catalog-container__aside-close" @click="closeCategory">
                        <the-svg-icon name="close" :size-w="24"></the-svg-icon>
                    </div>
                </div>

                <div class="catalog-container__aside-back" @click="closeCategory">
                    <the-svg-icon
                        name="arrow_right"
                        is-common
                        :size-w="16"
                        :size-h="24"
                    ></the-svg-icon>
                    Назад
                </div>

                <router-link
                    v-if="!isCatalog"
                    :to="{ name: 'Catalog' }"
                    class="catalog-aside__title"
                >
                    Каталог товаров
                </router-link>

                <catalog-menu
                    v-for="(catalogList, i) in formattedCategoryNames"
                    :key="i"
                    :catalog-list="catalogList"
                    :class="{
                        'is-active':
                            catalogList[0].slug === $route.params.slug ||
                            isCatalog ||
                            catalogList.map((el) => el.slug).includes($route.params.slug),
                    }"
                    @close-mobile-menu="closeCategory"
                ></catalog-menu>
            </div>
        </aside>

        <div class="catalog-container__body">
            <slot name="body"></slot>
            <div class="js-divider-stop"></div>
        </div>
    </div>
</template>

<style lang="scss">
    .catalog-container {
        padding-top: 24px;
        margin-bottom: 56px;

        @include bp($bp-desktop-sm) {
            padding-top: 0;
            margin-top: 40px;
            display: flex;
            align-items: flex-start;
            margin-bottom: 96px;
        }
    }

    .catalog-container__mobile-title {
        font-weight: 600;
        font-size: 30px;
        line-height: 34px;

        @include bp($bp-desktop-sm) {
            display: none;
        }
    }

    .catalog-container__mobile-desc {
        font-size: 16px;
        line-height: 24px;
        margin-top: 8px;

        @include bp($bp-desktop-sm) {
            display: none;
        }
    }

    .catalog-container__body {
        flex-grow: 1;
        margin-bottom: -24px;

        @include bp($bp-desktop-sm) {
            padding-left: 32px;
            margin-bottom: -48px;
            border-left: 1px solid var(--primary-border--light);
        }
    }

    .catalog-container__mobile-category {
        display: flex;
        font-size: 15px;
        line-height: 24px;
        color: var(--primary-text);
        padding: 12px 16px;
        margin: 16px -16px;
        background-color: var(--neutral--light);
        border-top: 1px solid var(--primary-border--light);
        border-bottom: 1px solid var(--primary-border--light);

        @include bp($bp-desktop-sm) {
            display: none;
        }
    }

    .catalog-container__mobile-icon {
        fill: var(--secondary-text);
        margin-right: 8px;
    }

    .catalog-container__mobile-arrow {
        margin-left: auto;
    }

    .catalog-container__aside {
        display: none;
        position: fixed;
        width: 100%;
        height: 100vh;
        height: stretch;
        top: 0;
        left: 0;
        background-color: #fff;
        z-index: z("mobile_catalog");
        overflow: auto;
        // ????

        @include bp($bp-desktop-sm) {
            display: block;
            position: static;
            width: 242px;
            height: auto;
            flex-shrink: 0;
            z-index: z("header_search");
            overflow: visible;
        }
    }

    .catalog-container__aside-wrap {
        @include bp($bp-desktop-sm) {
            padding-right: 42px;
        }
    }

    .catalog-container__aside--active {
        display: block;
    }

    .catalog-container__aside-header {
        position: relative;
        text-align: center;

        @include bp($bp-desktop-sm) {
            display: none;
        }
    }

    .catalog-container__aside-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        padding: 12px 0;
    }

    .catalog-container__aside-close {
        position: absolute;
        top: 12px;
        right: 16px;
    }

    .catalog-container__aside-back {
        display: flex;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        background-color: var(--neutral--light);
        padding: 12px 16px;
        border-top: 1px solid var(--primary-border--light);
        border-bottom: 1px solid var(--primary-border--light);

        @include bp($bp-desktop-sm) {
            display: none;
        }

        svg {
            margin-right: 8px;
            transform: rotate(180deg);
        }
    }

    .catalog-aside__title {
        display: none;

        @include bp($bp-desktop-sm) {
            display: block;
            font-weight: 600;
            font-size: 20px;
            line-height: 1;
            margin-bottom: 24px;
            color: var(--primary-text);
        }
    }
</style>
