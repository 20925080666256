var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"catalog-container"},[_c('h1',{staticClass:"catalog-container__mobile-title"},[_vm._t("title")],2),_c('div',{staticClass:"catalog-container__mobile-desc"},[_vm._t("description")],2),_c('div',{staticClass:"catalog-container__mobile-category",on:{"click":_vm.showCategory}},[_c('the-svg-icon',{staticClass:"catalog-container__mobile-icon",attrs:{"name":"menu_list","size-w":24}}),_vm._v(" Категории "),_c('the-svg-icon',{staticClass:"catalog-container__mobile-arrow",attrs:{"name":"arrow_right","is-common":"","size-w":16,"size-h":24}})],1),_c('aside',{staticClass:"catalog-container__aside js-scroll-catalog",class:{
            'catalog-container__aside--active': _vm.isAside,
        }},[_c('div',{staticClass:"catalog-container__aside-wrap js-scroll-catalog-wrap"},[_c('div',{staticClass:"catalog-container__aside-header"},[_c('div',{staticClass:"catalog-container__aside-title"},[_vm._t("title")],2),_c('div',{staticClass:"catalog-container__aside-close",on:{"click":_vm.closeCategory}},[_c('the-svg-icon',{attrs:{"name":"close","size-w":24}})],1)]),_c('div',{staticClass:"catalog-container__aside-back",on:{"click":_vm.closeCategory}},[_c('the-svg-icon',{attrs:{"name":"arrow_right","is-common":"","size-w":16,"size-h":24}}),_vm._v(" Назад ")],1),(!_vm.isCatalog)?_c('router-link',{staticClass:"catalog-aside__title",attrs:{"to":{ name: 'Catalog' }}},[_vm._v(" Каталог товаров ")]):_vm._e(),_vm._l((_vm.formattedCategoryNames),function(catalogList,i){return _c('catalog-menu',{key:i,class:{
                    'is-active':
                        catalogList[0].slug === _vm.$route.params.slug ||
                        _vm.isCatalog ||
                        catalogList.map(function (el) { return el.slug; }).includes(_vm.$route.params.slug),
                },attrs:{"catalog-list":catalogList},on:{"close-mobile-menu":_vm.closeCategory}})})],2)]),_c('div',{staticClass:"catalog-container__body"},[_vm._t("body"),_c('div',{staticClass:"js-divider-stop"})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }