<script>
    import { mapActions, mapState, mapGetters } from "vuex";
    import { metaInfo } from "@/core/MetaInfo";
    import CatalogContainer from "@tb/components/CatalogContainer";
    import SectionGoods from "@tb/components/SectionProducts";
    import CatalogBanner from "@tb/components/CatalogBanner";
    import CatalogSkeleton from "@tb/components/CatalogSkeleton";

    export default {
        name: "Catalog",
        components: {
            CatalogSkeleton,
            CatalogContainer,
            SectionGoods,
            CatalogBanner,
        },
        computed: {
            ...mapState("Catalog", ["categories", "loading"]),
            ...mapGetters("Catalog", ["mainCategories"]),
        },
        serverPrefetch() {
            let list = this.mainCategories.map((el) => el.id);
            return this.getCatalog({ list });
        },
        mounted() {
            if (!this.categories) {
                let list = this.mainCategories.map((el) => el.id);
                this.getCatalog({ list });
            }
        },
        methods: {
            ...mapActions("Catalog", ["getCatalog", "clearCatalog"]),
        },
        metaInfo() {
            return metaInfo.getMeta("catalog");
        },
    };
</script>

<template>
    <div class="l-container">
        <catalog-container is-catalog>
            <template #title> Каталог товаров </template>

            <template #body>
                <catalog-banner
                    is-catalog
                    title="Каталог товаров"
                    description="Все необходимое для безопасного и комфортного путешествия"
                ></catalog-banner>

                <div class="b-sections">
                    <div v-if="loading" class="b-sections__skeleton">
                        <catalog-skeleton></catalog-skeleton>
                    </div>

                    <section-goods
                        v-if="!loading"
                        :section="category"
                        :id="i"
                        v-for="(category, i) in categories"
                        :key="i"
                    ></section-goods>
                </div>
            </template>
        </catalog-container>
    </div>
</template>

<style lang="scss">
    .b-sections {
        position: relative;
        min-height: 400px;
    }

    .b-sections__skeleton {
        //position: absolute;
        //z-index: 1000;
        //opacity: 0.5;
        //width: 100%;
    }
</style>
